import { ImageDataModel } from "@/shared/models/models";

export class FundTypeDto {
    constructor(options) {
        options = options || {};
        this.typeFundID = _.parseNumber(options.typeFundID, -1);
        this.description = options.description || null;
        this.hidden = _.parseBool(options.hidden, false);
        this.isSystem = _.parseBool(options.isSystem, false);
        this.inactive = _.parseBool(options.inactive, false);
    }

    get isNew() { return this.typeFundID == -1; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class AutoReconMappingsDto {
    constructor(options) {
        options = options || {};
        this.autoReconMappingID = _.parseNumber(options.autoReconMappingID, 0);
        this.bankCompanyID = options.bankCompanyID || null;
        this.checkCodes = options.checkCodes || null;
        this.wireOutCodes = options.wireOutCodes || null;
        this.wireInCodes = options.wireInCodes || null;
        this.depositCodes = options.depositCodes || null;
        this.lookBackDays = options.lookBackDays || null;
        this.specialFormat = _.parseNumber(options.specialFormat, null);
        this.forwardDays = options.forwardDays || null;
        this.altAccountNumber = options.altAccountNumber || null;
        this.ignoreDepSlip = _.parseBool(options.ignoreDepSlip, false);
        this.dailyRecons = _.parseBool(options.dailyRecons, false);
        this.depositNumbers = _.parseBool(options.depositNumbers, false);
        // this.bankName = options.bankName || null;
        // this.specialFormatName = options.specialFormatName || null;
        this.autoReconFileTypeID = _.parseNumber(options.autoReconFileTypeID, 0);
    }

    get isNew() { return this.autoReconMappingID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.bankCompanyID)) {
            errorList.push("A Bank is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class AutoConsolidatePayeeDto {
    constructor(options) {
        options = options || {};
        this.autoConsolidatePayeeID = _.parseNumber(options.autoConsolidatePayeeID, -1);
        this.payeeCompanyID = _.parseNumber(options.payeeCompanyID);
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.inactive = _.parseBool(options.inactive, false);
        this.requireAccountCode = _.parseBool(options.requireAccountCode, false);
        this.bankCompanyID =  _.parseNumber(options.bankCompanyID);
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.gfNo = options.gfNo || null;
        // this.branchID = _.parseNumber(options.branchID);
        this.lossAdvanceType = options.lossAdvanceType || null;
        this.allBranches = _.parseBool(options.allBranches, false);
        this.selected = options.selected || null;
        this.interCompanyTransfer = options.interCompanyTransfer || null;
        this.splitFee = options.splitFee || null;
        this.clearingAccount = options.clearingAccount || null;
        this.franchiseTaxBoardAccount = options.franchiseTaxBoardAccount || null;
        this.payeeCompanyName = options.payeeCompanyName;
        this.bankCompanyName = options.bankCompanyName || null;
        this.branchList = options.branchList;
        this.branchIDs = _.split(options.branchList || "", ',').map(Number);
    }

    get isNew() { return this.autoConsolidatePayeeID == -1; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.bankCompanyID)) {
            errorList.push("Bank is required.");
        }
        if (_.isNil(this.regionID)) {
            errorList.push("Region is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class CheckCoverLetterDto {
    constructor(options) {
        options = options || {};

        this.checkCoverLetterID = _.parseNumber(options.checkCoverLetterID, -1);
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.branchID = _.parseNumber(options.branchID);
        this.branchIDs = options.branchIDs || [];
        if(!_.includes(this.branchIDs, this.branchID)) this.branchIDs.push(this.branchID);
        this.coverLetterName = options.coverLetterName || null;
        this.documentTemplateName = options.documentTemplateName || null;
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, null);
        this.roleTypeID = _.parseNumber(options.roleTypeID , null);
        this.payoffDescriptionID = _.parseNumber(options.payoffDescriptionID, null);
    }

    get isNew() { return this.coverLetterID == -1; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.regionID)) {
            errorList.push("Region is required.");
        }
        if (_.isNil(this.branchID)) {
            errorList.push("Branch is required.");
        }
        if(_.isNil(this.coverLetterName)) {
            errorList.push("Cover letter name is required.");
        }
        if(_.isNil(this.documentTemplateID) || _.isNil(this.documentTemplateName)) {
            errorList.push("Document is required");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class MoneyMovementApprovalRangeDto {
    constructor(options) {
        options = options || {};
        this.moneyMovementApprovalRangeID = _.parseNumber(options.moneyMovementApprovalRangeID, 0);
        this.approverUsersID = _.parseNumber(options.approverUsersID);
        this.inactive = _.parseBool(options.inactive, false);
        if (_.size(options.regionList || []) == 0 || (options.regionList || "").length > 0) {
            this.regionList = _.split(options.regionList || "", ',').map(Number);
        } else {
            this.regionList = options.regionList || [];
        }
        if (_.size(options.branchList || []) == 0 || (options.branchList || "").length > 0) {
            this.branchList = _.split(options.branchList || "", ',').map(Number);
        } else {
            this.branchList = options.branchList || [];
        }
        this.allBranches = _.parseBool(options.allBranches, false);
        this.moneyMovementType = _.parseNumber(options.moneyMovementType, 1);
        this.notificationType = _.parseNumber(options.notificationType, 1);
        this.fromAmount = _.parseNumber(options.fromAmount, 0);
        this.toAmount = _.parseNumber(options.toAmount, 0);
        this.inactiveUser = _.parseBool(options.inactiveUser, false);
    }

    get isNew() { return this.moneyMovementApprovalRangeID == 0; }

    get validationErrors() {
        let errorList = [];
        if (!_.any(this.regionList)) {
            errorList.push("Region is required.");
        }
        if (!_.any(this.branchList) && !this.allBranches) {
            errorList.push("Branch is required.");
        }
        return errorList;
    }

    toDataObject() {
        this.regionList = _.toString(this.regionList);
        this.branchList = _.toString(this.branchList);
        return _.toPlainObject(this);
    }
}

export class MoneyMovementApprovalThresholdDto {
    constructor(options) {
        options = options || {};
        this.moneyMovementApprovalThresholdID = _.parseNumber(options.moneyMovementApprovalThresholdID, 0);
        this.inactive = _.parseBool(options.inactive, false);
        if (_.size(options.regionList || []) == 0 || (options.regionList || "").length > 0) {
            this.regionList = _.split(options.regionList || "", ',').map(Number);
        } else {
            this.regionList = options.regionList || [];
        }
        this.allRegions = _.parseBool(options.allRegions, false);
        this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, 1);
        this.thresholdAmount = _.parseNumber(options.thresholdAmount, 0);
    }

    get isNew() { return this.moneyMovementApprovalThresholdID == 0; }

    get validationErrors() {
        let errorList = [];
        if (!_.any(this.regionList)) {
            errorList.push("Region is required.");
        }
        return errorList;
    }

    toDataObject() {
        this.regionList = _.toString(this.regionList);
        return _.toPlainObject(this);
    }
}

export class LossAdvanceReasonCategoryDto {
    constructor(options) {
        options = options || {};
        this.lossAdvanceReasonCategoryID = _.parseNumber(options.lossAdvanceReasonCategoryID, 0);
        this.inactive = _.parseBool(options.inactive, false);
        if (_.size(options.regionList || []) == 0 || (options.regionList || "").length > 0) {
            this.regionList = _.split(options.regionList || "", ',').map(Number);
        } else {
            this.regionList = options.regionList || [];
        }
        this.allRegions = _.parseBool(options.allRegions, false);
        this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, 3);
        this.categoryName = options.categoryName || null;
    }

    get isNew() { return this.moneyMovementApprovalThresholdID == 0; }

    get validationErrors() {
        let errorList = [];
        if (!_.any(this.regionList)) {
            errorList.push("Region is required.");
        }
        return errorList;
    }

    toDataObject() {
        this.regionList = _.toString(this.regionList);
        return _.toPlainObject(this);
    }
}

export class LossAdvanceReasonDto {
    constructor(options) {
        options = options || {};
        this.lossAdvanceReasonID = _.parseNumber(options.lossAdvanceReasonID, 0);
        this.regionID = _.parseNumber(options.regionID, 0);
        this.branchID = _.parseNumber(options.branchID, 0);
        this.lossAdvanceReasonCategoryID = _.parseNumber(options.lossAdvanceReasonCategoryID, 0);
        this.reason = options.reason || null;
        this.documentTemplateName = options.documentTemplateName || null;
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, 0);
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, null);
        this.moneyMovementTypeID = _.parseNumber(options.moneyMovementTypeID, 3);
        this.inactive = _.parseBool(options.inactive, false);
        this.inactiveCategory = _.parseBool(options.inactiveCategory, false);
    }

    get isNew() { return this.lossAdvanceReasonID == 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.regionID)) {
            errorList.push("Region is required.");
        }
        if (_.isNil(this.branchID)) {
            errorList.push("Branch is required.");
        }
        if (_.isNil(this.lossAdvanceReasonCategoryID)) {
            errorList.push("Category is required.");
        }
        if (_.isNil(this.reason)) {
            errorList.push("Reason is required.");
        }
        return errorList;
    }

    toDataObject() {
        return _.toPlainObject(this);
    }
}

export class EscrowUnitDto {
	constructor(options) {
        options = options || {};
        this.escrowUnitID = _.parseNumber(options.escrowUnitID, 0);
        this.shortDescription = options.shortDescription || null;
        this.description = options.description || null;
        this.prefix = options.prefix;
        this.suffix = options.suffix;
        this.fileNumberPoolID = _.parseNumber(options.fileNumberPoolID, null);
        this.filePoolDisplay = options.filePoolDisplay;
        this.leaderUsersID = _.parseNumber(options.leaderUsersID, null);
        this.leaderDisplay = options.leaderDisplay;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay;
        this.scannedSignature = options.scannedSignature || null;
        this.memberUserIDs = options.memberUserIDs;
        this.signatureImage = new ImageDataModel(options.signatureImage);
        this.unitChecks = options.unitChecks;
    }

    get isNew() { return this.escrowUnitID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.shortDescription)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }
    get verifiedItem() {
        let errorResponse = {};
        if (this.regionID == 0) {
            errorResponse.requiredRegion = true;
        }
        if (_.isEmpty(this.shortDescription)) {
            errorResponse.requiredName = true;
        }
        if (_.isEmpty(this.description)) {
            errorResponse.requiredDesc = true;
        }

        return errorResponse;
    }

    toDataObject() {
        let result = _.omit(this, ["signatureImage"]);
        let dataUrl = this.signatureImage.dataUrl;
        result.scannedSignature = _.isEmpty(dataUrl)
            ? ''
            : dataUrl.substring(dataUrl.indexOf("base64,") + 7)
        return result;
    }
}

export class EscrowUnitCheckDto {
	constructor(options) {
        options = options || {};
        this.escrowUnitCheckID = _.parseNumber(options.escrowUnitCheckID, 0);
        this.bankCompanyID = _.parseNumber(options.bankCompanyID, null);
        this.escrowUnitID = _.parseNumber(options.escrowUnitID, null);
        this.checkStart = _.parseNumber(options.checkStart, 0);
        this.checkEnd = _.parseNumber(options.checkEnd, 0);
        this.checkNumberType = _.parseNumber(options.checkNumberType, 0);
        this.isDeleted = _.parseBool(options.isDeleted);
    }

    get isNew() { return this.escrowUnitCheckID === 0; }

    toDataObject() { return _.toPlainObject(this); }
}

export class EscheatDormancyPeriodDto {
    constructor(options) {
        options = options || {};
        this.escheatDormancyPeriodID = _.parseNumber(options.escheatDormancyPeriodID, 0);
        this.usStatesID = _.parseNumber(options.usStatesID, null);
        this.monthsAfterCheckDate = _.parseNumber(options.monthsAfterCheckDate, null);
        this.inactive = _.parseBool(options.inactive, false);
        this.modifiedBy = _.parseNumber(options.inactive, null);
        this.modifiedDate = _.parseDateString(options.modifiedDate, null);
    }

    get isNew() { return this.escheatDormancyPeriodID === 0; }

    toDataObject() { return _.toPlainObject(this); }
}